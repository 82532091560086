import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';
import i18n from '../../../i18n';

import Link from '../Link';
import Logo from '../../../../images/footer_logo.svg';
import CMHRLogo from '../../../../images/footer_cmhr_logo.svg';
import CMHRLogoFR from '../../../../images/footer_cmhr_logo_fr.svg';
import ExternalArrow from '../../../../images/external_link_arrow.svg';

import {
  ABOUT_ARTIST_PATH,
  STORIES_PATH,
  ABOUT_BLANKET_PATH,
  BEAR_WITNESS_PATH,
  TEACHER_GUIDE_PATH,
  TEACHER_GUIDE_PATH_FR,
  CONTACT_PATH,
  SITE_MAP_PATH,
} from '../../utils/urlPaths';

function Footer() {
  const { t } = useTranslation();

  const footerOptions = [
    { url: '/', option: t('nav_title.homepage') },
    { url: STORIES_PATH, option: t('nav_options.stories') },
    { url: BEAR_WITNESS_PATH, option: t('nav_options.bear_witness') },
    { url: TEACHER_GUIDE_PATH, option: t('nav_options.teacher_guide') },
    { url: t('resource_url'), option: t('nav_options.resources') },
    { url: ABOUT_BLANKET_PATH, option: t('nav_options.about_blanket') },
    { url: ABOUT_ARTIST_PATH, option: t('nav_options.about_artist') },
    { url: CONTACT_PATH, option: t('nav_options.contact_us') },
  ];

  const teacherGuideFooterOptions = [
    { url: '#lesson_1', option: t('nav_teacher_guide_options.lesson_1') },
    { url: '#lesson_2', option: t('nav_teacher_guide_options.lesson_2') },
    { url: '#lesson_3', option: t('nav_teacher_guide_options.lesson_3') },
    { url: '#lesson_4', option: t('nav_teacher_guide_options.lesson_4') },
    { url: '#workshop', option: t('nav_teacher_guide_options.workshops') },
    { url: '#contact', option: t('nav_teacher_guide_options.contact') },
    { url: '/', option: t('nav_teacher_guide_options.main') },
  ];

  const isTeacherGuidePage = () => {
    return (
      window.location.href.indexOf(TEACHER_GUIDE_PATH) > -1 || window.location.href.indexOf(TEACHER_GUIDE_PATH_FR) > -1
    );
  };

  return (
    <footer className="page-footer">
      <h2 className="offscreen-navigation">{t('footer.title')}</h2>
      <div className="desktop-footer">
        <div className="desktop-footer-content">
          <div className="footer-logo">
            <Link to="/">
              <img src={Logo} alt={t('footer.logo_alt')} />
              {t('nav_title.default')}
            </Link>
          </div>
          <div className="menu-options">
            <nav aria-label={t('footer.navigation_aria')}>
              <h3 id="footerNavigation" className="offscreen-navigation">
                {t('footer.navigation_title')}
              </h3>
              {isTeacherGuidePage() ? (
                <ul className="list-unstyled" aria-labelledby="footerNavigation">
                  {teacherGuideFooterOptions.map((item) => (
                    <li key={item.url}>
                      {item.url === '/' ? (
                        <Link to={item.url}>{item.option}</Link>
                      ) : (
                        <HashLink to={item.url}>{item.option}</HashLink>
                      )}
                    </li>
                  ))}
                </ul>
              ) : (
                <ul className="list-unstyled" aria-labelledby="footerNavigation">
                  {footerOptions.map((item) => (
                    <li key={item.url}>
                      {(item.url as string).startsWith('/') ? (
                        <Link to={item.url}>{item.option}</Link>
                      ) : (
                        <a href={item.url} target="_blank" rel="noreferrer">
                          {item.option}
                        </a>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </nav>
          </div>
        </div>
      </div>
      <div className="footer-content">
        <div className="footer-logo">
          <Link to="/">
            <img src={Logo} alt={t('footer.logo_alt')} />
            {t('nav_title.default')}
          </Link>
        </div>
        <h3 id="footerAdditionals" className="offscreen-navigation">
          {t('footer.additional_links')}
        </h3>
        <ul className="list-unstyled" aria-labelledby="footerAdditionals">
          <li>
            <Link to={SITE_MAP_PATH}>{t('footer.sitemap')}</Link>
          </li>
          <li>
            <a href={t('external_links.privacy')} target="_blank" rel="noreferrer">
              {t('footer.privacy')}
              <img src={ExternalArrow} alt={t('footer.external_privacy_link_alt_text')} />
            </a>
          </li>
          <li>
            <a href={`${t('external_links.legal')}`} target="_blank" rel="noreferrer">
              {t('footer.legal')}
              <img src={ExternalArrow} alt={t('footer.external_legal_link_alt_text')} />
            </a>
          </li>
          <li>
            <a href={`${t('external_links.conduct')}`} target="_blank" rel="noreferrer">
              {t('footer.conduct')}
              <img src={ExternalArrow} alt={t('footer.external_conduct_link_alt_text')} />
            </a>
          </li>
        </ul>
        <div className="cmhr-logo">
          <img src={i18n.language === 'en' ? CMHRLogo : CMHRLogoFR} alt={t('footer.logo_alt')} />
        </div>
        <div className="footer-copyright">{t('footer.copyright')}</div>
      </div>
    </footer>
  );
}

export default Footer;
