import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import useTranslatedUrl from '../utils/useTranslatedUrl';
import useLayoutData from '../utils/layoutFormatData';
import { Footer, Navbar, SafeSpaceCurtain, SkipLinks } from '../index';
import { selectIsModalOpen } from '../../store/settings/settingsSlice';

interface Props {
  children?: React.ReactNode;
}

function Layout({ children }: Props) {
  const { t } = useTranslation();
  const location = useLocation();
  const modalOpen = useSelector(selectIsModalOpen);
  const layoutData = useLayoutData(location.pathname);

  const currentUrl = useTranslatedUrl({
    path: window.location.pathname,
    fullPath: false,
    useCurrentLanguage: true,
  });

  // Allow dynamic fetching of page title
  /* eslint-disable @typescript-eslint/ban-ts-comment */
  // @ts-ignore
  const titleKey = t(`metadata.${currentUrl}.title`);

  return (
    <div className={`layout ${layoutData?.className}`}>
      <Helmet>
        <title>{titleKey || t('title')}</title>
      </Helmet>
      <SafeSpaceCurtain />
      <SkipLinks />
      <Navbar title={layoutData?.title} />
      <main aria-hidden={modalOpen}>
        <div className={`site-layout-content ${window.location.href.indexOf('story/') > -1 ? 'story' : ''}`}>
          {children}
        </div>
      </main>
      {layoutData?.includeFooter && <Footer />}
    </div>
  );
}

Layout.defaultProps = {
  children: null,
};

export default Layout;
