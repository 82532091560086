import React from 'react';
import { useTranslation } from 'react-i18next';
import SafeSpace from '../../../../images/safespace.svg';

interface Props {
  isOpen: boolean;
  onClick: () => void;
}

export const SafeSpaceButton = ({ isOpen, onClick }: Props): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <button
      className={`am-menu__button ${isOpen ? 'safe-space---open' : 'safe-space---closed'}`}
      onClick={onClick}
      data-toggle="collapse"
      data-target="#navbarSafeSpaceContent"
      aria-controls="navbarSafeSpaceContenta"
      aria-expanded="false"
      aria-label={`${t(isOpen ? 'close_prefix' : 'open_prefix')} ${t('safe_space_open')}`}
      type="button"
    >
      {isOpen ? (
        <div className="safe-space-x">
          <div className="safe-space-x-one" />
          <div className="safe-space-x-two" />
        </div>
      ) : (
        <img src={SafeSpace} className="safe-space-img" alt={t('safe_space_alt')} />
      )}
    </button>
  );
};
