export const WELCOME_PATH = '/';
export const ABOUT_ARTIST_PATH = '/carey-newman';
export const ABOUT_ARTIST_PATH_FR = '/carey-newman';
export const ABOUT_BLANKET_PATH = '/about-the-blanket';
export const ABOUT_BLANKET_PATH_FR = '/au-sujet-de-la-couverture';
export const EXPLORE_BLANKET_PATH = '/explore';
export const EXPLORE_BLANKET_PATH_FR = '/explorer';
export const BEAR_WITNESS_PATH = '/bear-witness';
export const BEAR_WITNESS_PATH_FR = '/se-porter-temoin';
export const TEACHER_GUIDE_PATH = '/teacher-guide';
export const TEACHER_GUIDE_PATH_FR = '/guide-pedagogique';
export const CONTACT_PATH = '/contact-us';
export const CONTACT_PATH_FR = '/pour-nous-joindre';
export const SITE_MAP_PATH = '/sitemap';
export const SITE_MAP_PATH_FR = '/plan-du-site';

export const STORIES_PATH = '/stories';
export const STORIES_PATH_FR = '/histoires';
export const STORY_MUSH_BOWL_PATH = '/story/mush-hole-bowl';
export const STORY_MUSH_BOWL_PATH_FR = '/histoire/bol-a-bouillie';
export const STORY_CHILD_SHOE_PATH = '/story/child-shoe';
export const STORY_CHILD_SHOE_PATH_FR = '/histoire/chaussure-enfant';
export const STORY_BROKEN_ANGEL_PATH = '/story/broken-angel';
export const STORY_BROKEN_ANGEL_PATH_FR = '/histoire/ange-brise';
export const STORY_BRICK_PATH = '/story/brick';
export const STORY_BRICK_PATH_FR = '/histoire/brique';
export const STORY_STONE_PATH = '/story/inuvik-stone';
export const STORY_STONE_PATH_FR = '/histoire/pierre-dinuvik';
export const STORY_SKATE_PATH = '/story/skate';
export const STORY_SKATE_PATH_FR = '/histoire/patin';
export const STORY_LETTER_PATH = '/story/moose-cree-letter';
export const STORY_LETTER_PATH_FR = '/histoire/lettre-en-cri-de-moose';
export const STORY_DOOR_PATH = '/story/door';
export const STORY_DOOR_PATH_FR = '/histoire/porte';
export const STORY_BRANCH_PATH = '/story/tree-branch';
export const STORY_BRANCH_PATH_FR = '/histoire/branche-darbre';
export const STORY_BRAIDS_PATH = '/story/braids';
export const STORY_BRAIDS_PATH_FR = '/histoire/tresses';
