export interface StoryManagerItems {
  story_name: string;
  isAvailable: boolean;
}

export const STORY_MANAGER: StoryManagerItems[] = [
  { story_name: 'stone', isAvailable: true },
  { story_name: 'brick', isAvailable: true },
  { story_name: 'skate', isAvailable: true },
  { story_name: 'letter', isAvailable: true },
  { story_name: 'door', isAvailable: true },
  { story_name: 'branch', isAvailable: true },
  { story_name: 'braids', isAvailable: true },
  { story_name: 'mushbowl', isAvailable: true },
  { story_name: 'child_shoe', isAvailable: true },
  { story_name: 'angel', isAvailable: true },
];
