import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  selectSafeSpaceWarningShown,
  setSafeSpaceWarningShown,
  setIsModalOpen,
} from '../../../store/settings/settingsSlice';
import { selectIsMobile, selectIsTablet } from '../../../store/ui/uiSlice';
import bkgImgMobile from '../../../../images/mobile-menu-bg.png';
import bkgImgTablet from '../../../../images/tablet-menu-bg.png';
import bkgImgDesktop from '../../../../images/desktop-menu-bg.png';

export default function SafeSpaceCurtain() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isShown, setIsShown] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const warningShown: boolean = useSelector(selectSafeSpaceWarningShown);
  const isMobile = useSelector(selectIsMobile);
  const isTablet = useSelector(selectIsTablet);

  useEffect(() => {
    if (!warningShown) {
      dispatch(setIsModalOpen(true));
    }

    const timer = setTimeout(() => {
      setIsShown(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  const classNames = () => {
    if (isClosing) {
      return 'close-curtain';
    }
    if (!isShown || (isShown && warningShown)) {
      return 'hidden';
    }
    return 'show-curtain';
  };

  const closeCurtain = () => {
    setIsClosing(true);
    dispatch(setSafeSpaceWarningShown(true));
    dispatch(setIsModalOpen(false));
  };

  /* Trick to fetch background img immediately */
  const bkgImg = () => {
    if (isMobile) return bkgImgMobile;
    if (isTablet) return bkgImgTablet;

    return bkgImgDesktop;
  };

  const curtainHtml = `<h2 class="offscreen-heading" id="curtain-heading">${t('safe_space_curtain.heading')}</h2>${t(
    'safe_space_curtain.main_text'
  )}`;
  return (
    <div className={`safe-space-curtain ${classNames()}`}>
      <img src={bkgImg()} style={{ display: 'none' }} alt="" />
      {/* eslint-disable react/no-danger */}
      {/* eslint-disable jsx-a11y/no-noninteractive-tabindex */}
      <section
        className="text-container"
        aria-labelledby="curtain-heading"
        dangerouslySetInnerHTML={{ __html: curtainHtml }}
        tabIndex={0}
      />
      <div className="action-container">
        <button type="button" className="continue-btn" onClick={() => closeCurtain()}>
          {t('safe_space_curtain.continue')}
        </button>
      </div>
    </div>
  );
}
