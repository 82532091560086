import { useSelector } from 'react-redux';
import { ROUTES } from '../App';
import i18n from '../../i18n';
import { selectInitialLanguage, selectBaseEnUrl, selectBaseFrUrl } from '../../store/settings/settingsSlice';

export default function useTranslatedUrl({ path = '', fullPath = false, useCurrentLanguage = false, translatedLanguage = '' } = {}) {
  const initialLanguage = useSelector(selectInitialLanguage);
  const baseEnUrl = useSelector(selectBaseEnUrl);
  const baseFrUrl = useSelector(selectBaseFrUrl);

  const language = useCurrentLanguage ? i18n.language : (translatedLanguage ? translatedLanguage : initialLanguage);

  if (language === 'en') {
    const tempPath = ROUTES.find((route) => route.fr === path)?.en || path;
    if (fullPath) {
      return `${baseEnUrl}${tempPath}`;
    }
    return tempPath;
  }

  const tempPath = ROUTES.find((route) => route.en === path)?.fr || path;
  return fullPath ? `${baseFrUrl}${tempPath}` : tempPath;
}
