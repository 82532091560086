import { useTranslation } from 'react-i18next';

import {
  ABOUT_ARTIST_PATH,
  ABOUT_ARTIST_PATH_FR,
  STORIES_PATH,
  STORIES_PATH_FR,
  STORY_MUSH_BOWL_PATH,
  STORY_MUSH_BOWL_PATH_FR,
  STORY_CHILD_SHOE_PATH,
  STORY_CHILD_SHOE_PATH_FR,
  STORY_BROKEN_ANGEL_PATH,
  STORY_BROKEN_ANGEL_PATH_FR,
  STORY_BRICK_PATH,
  STORY_BRICK_PATH_FR,
  STORY_STONE_PATH,
  STORY_STONE_PATH_FR,
  STORY_SKATE_PATH,
  STORY_SKATE_PATH_FR,
  STORY_LETTER_PATH,
  STORY_LETTER_PATH_FR,
  STORY_DOOR_PATH,
  STORY_DOOR_PATH_FR,
  STORY_BRANCH_PATH,
  STORY_BRANCH_PATH_FR,
  STORY_BRAIDS_PATH,
  STORY_BRAIDS_PATH_FR,
  EXPLORE_BLANKET_PATH,
  EXPLORE_BLANKET_PATH_FR,
  ABOUT_BLANKET_PATH,
  ABOUT_BLANKET_PATH_FR,
  BEAR_WITNESS_PATH,
  BEAR_WITNESS_PATH_FR,
  TEACHER_GUIDE_PATH,
  TEACHER_GUIDE_PATH_FR,
  CONTACT_PATH,
  CONTACT_PATH_FR,
  SITE_MAP_PATH,
  SITE_MAP_PATH_FR,
} from './urlPaths';

interface LayoutProps {
  className?: string;
  title?: string;
  includeFooter?: boolean;
}

export default function useLayoutData(path = '') {
  const { t } = useTranslation();

  switch (path.toLowerCase()) {
    case ABOUT_ARTIST_PATH:
    case ABOUT_ARTIST_PATH_FR:
      return {
        className: 'layout-stories about-artist-layout',
        title: t('nav_title.about_artist'),
        includeFooter: true,
      };
    case ABOUT_BLANKET_PATH:
    case ABOUT_BLANKET_PATH_FR:
      return {
        className: '',
        title: t('nav_title.about_blanket'),
        includeFooter: true,
      };
    case BEAR_WITNESS_PATH:
    case BEAR_WITNESS_PATH_FR:
      return {
        className: "witness-layout",
        title: t('nav_title.bear_witness'),
        includeFooter: true,
      };
    case EXPLORE_BLANKET_PATH:
    case EXPLORE_BLANKET_PATH_FR:
      return {
        className: "layout-blanket", 
        title: t('nav_title.view_blanket'),
        includeFooter: false
      };
    case CONTACT_PATH:
    case CONTACT_PATH_FR:
      return {
        className: "layout-contact",
        title: t('nav_title.contact_us'),
        includeFooter: true,
      };
    case '/':
    case '/test':
      return {
        className: "layout-home",
        title: t('nav_title.homepage'),
        includeFooter: true,
      };
    case STORIES_PATH:
    case STORIES_PATH_FR:
      return {
        className: 'select-story-layout',
        title: t('nav_title.select_story'),
        includeFooter: true,
      };
    case SITE_MAP_PATH:
    case SITE_MAP_PATH_FR:
      return {
        className: "layout-site-map",
        title: t('nav_options.site_map'),
        includeFooter: true,
      };
    case STORY_BRAIDS_PATH:
    case STORY_BRAIDS_PATH_FR:
      return {
        className: 'layout-stories',
        title: t('stories.braids.title'),
        includeFooter: true,
      };
    case STORY_BRANCH_PATH:
    case STORY_BRANCH_PATH_FR:
      return {
        className: 'layout-stories',
        title: t('stories.branch.title'),
        includeFooter: true,
      };
    case STORY_BRICK_PATH:
    case STORY_BRICK_PATH_FR:
      return {
        className: 'layout-stories',
        title: t('stories.brick.title'),
        includeFooter: true,
      };
    case STORY_BROKEN_ANGEL_PATH:
    case STORY_BROKEN_ANGEL_PATH_FR:
      return {
        className: 'layout-stories',
        title: t('stories.angel.title'),
        includeFooter: true,
      };
    case STORY_CHILD_SHOE_PATH:
    case STORY_CHILD_SHOE_PATH_FR:
      return {
        className: 'layout-stories',
        title: t('stories.child_shoe.title'),
        includeFooter: true,
      };
    case STORY_DOOR_PATH:
    case STORY_DOOR_PATH_FR:
      return {
        className: 'layout-stories',
        title: t('stories.door.title'),
        includeFooter: true,
      };
    case STORY_LETTER_PATH:
    case STORY_LETTER_PATH_FR:
      return {
        className: 'layout-stories',
        title: t('stories.letter.title'),
        includeFooter: true,
      };
    case STORY_MUSH_BOWL_PATH:
    case STORY_MUSH_BOWL_PATH_FR:
      return {
        className: 'layout-stories',
        title: t('stories.mushbowl.title'),
        includeFooter: true,
      };
    case STORY_SKATE_PATH:
    case STORY_SKATE_PATH_FR:
      return {
        className: 'layout-stories',
        title: t('stories.skate.title'),
        includeFooter: true,
      };
    case STORY_STONE_PATH:
    case STORY_STONE_PATH_FR:
      return {
        className: 'layout-stories',
        title: t('stories.stone.title'),
        includeFooter: true,
      };
    case TEACHER_GUIDE_PATH:
    case TEACHER_GUIDE_PATH_FR:
      return {
        className: 'layout-teacher-guide',
        title: t('nav_title.teacher_guide'),
        includeFooter: true,
      };
    case '':
      return {};
  }
}
