export const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export const copyUrl = (url: string) => {
  navigator.clipboard.writeText(url);
};

export const shareFB = (url: string) => {
  openInNewTab(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`);
};
