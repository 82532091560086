import { configureStore } from '@reduxjs/toolkit';
import settingsReducer from './settings/settingsSlice';
import shareReducer from './share/shareSlice';
import uiReducer, {
  resizeScreen,
  updateMediaQuery,
  tabletMediaQuery,
  desktopMediaQuery,
  reducedMotionMediaQuery,
} from './ui/uiSlice';

export const store = configureStore({
  reducer: {
    ui: uiReducer,
    settings: settingsReducer,
    share: shareReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Media Query setup
window.addEventListener('resize', () => {
  store.dispatch(resizeScreen(window.innerWidth));
});

export const updateMediaQueries = () => {
  store.dispatch(
    updateMediaQuery({
      isTablet: tabletMediaQuery.matches,
      isDesktop: desktopMediaQuery.matches,
      isReducedMotion: reducedMotionMediaQuery.matches,
    })
  );
};

const handleTablet = () => {
  updateMediaQueries();
};
tabletMediaQuery.addEventListener('change', handleTablet);

const handleDesktop = () => {
  updateMediaQueries();
};
desktopMediaQuery.addEventListener('change', handleDesktop);

updateMediaQueries();
