import React, { Suspense, lazy } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';

import { setLanguageSettings } from '../store/settings/settingsSlice';
import Layout from './layout/Layout';

import {
  ABOUT_ARTIST_PATH,
  ABOUT_ARTIST_PATH_FR,
  STORIES_PATH,
  STORIES_PATH_FR,
  STORY_MUSH_BOWL_PATH,
  STORY_MUSH_BOWL_PATH_FR,
  STORY_CHILD_SHOE_PATH,
  STORY_CHILD_SHOE_PATH_FR,
  STORY_BROKEN_ANGEL_PATH,
  STORY_BROKEN_ANGEL_PATH_FR,
  STORY_BRICK_PATH,
  STORY_BRICK_PATH_FR,
  STORY_STONE_PATH,
  STORY_STONE_PATH_FR,
  STORY_SKATE_PATH,
  STORY_SKATE_PATH_FR,
  STORY_LETTER_PATH,
  STORY_LETTER_PATH_FR,
  STORY_DOOR_PATH,
  STORY_DOOR_PATH_FR,
  STORY_BRANCH_PATH,
  STORY_BRANCH_PATH_FR,
  STORY_BRAIDS_PATH,
  STORY_BRAIDS_PATH_FR,
  EXPLORE_BLANKET_PATH,
  EXPLORE_BLANKET_PATH_FR,
  ABOUT_BLANKET_PATH,
  ABOUT_BLANKET_PATH_FR,
  BEAR_WITNESS_PATH,
  BEAR_WITNESS_PATH_FR,
  TEACHER_GUIDE_PATH,
  TEACHER_GUIDE_PATH_FR,
  CONTACT_PATH,
  CONTACT_PATH_FR,
  SITE_MAP_PATH,
  SITE_MAP_PATH_FR,
} from './utils/urlPaths';

import { StoryManagerItems, STORY_MANAGER } from './utils/storyFilter';

const MainSeries = lazy(() => import('./pages/MainSeries'));
const AboutArtistPage = lazy(() => import('./pages/AboutArtistPage/index'));
const StoryMushBowl = lazy(() => import('./pages/StoryMushBowl/index'));
const StoryChildShoe = lazy(() => import('./pages/StoryChildShoe/index'));
const StoryBrokenAngel = lazy(() => import('./pages/StoryBrokenAngel/index'));
const StoryBrick = lazy(() => import('./pages/StoryBrick/index'));
const StoryStone = lazy(() => import('./pages/StoryStone/index'));
const StorySkate = lazy(() => import('./pages/StorySkate/index'));
const StoryLetter = lazy(() => import('./pages/StoryLetter/index'));
const StoryDoor = lazy(() => import('./pages/StoryDoor/index'));
const StoryBranch = lazy(() => import('./pages/StoryBranch/index'));
const StoryBraids = lazy(() => import('./pages/StoryBraids/index'));
const AboutBlanket = lazy(() => import('./pages/AboutBlanket/index'));
const BearWitness = lazy(() => import('./pages/BearWitness/index'));
const TeacherGuide = lazy(() => import('./pages/TeacherGuide/index'));
const ContactUs = lazy(() => import('./pages/ContactUs/index'));
const Sitemap = lazy(() => import('./pages/Sitemap/index'));

export const ROUTES = [
  { en: ABOUT_ARTIST_PATH, fr: ABOUT_ARTIST_PATH_FR, element: <AboutArtistPage /> },
  { en: STORIES_PATH, fr: STORIES_PATH_FR, element: <MainSeries /> },
  { en: STORY_MUSH_BOWL_PATH, fr: STORY_MUSH_BOWL_PATH_FR, element: <StoryMushBowl />, story_name: 'mushbowl' },
  { en: STORY_CHILD_SHOE_PATH, fr: STORY_CHILD_SHOE_PATH_FR, element: <StoryChildShoe />, story_name: 'child_shoe' },
  { en: STORY_BROKEN_ANGEL_PATH, fr: STORY_BROKEN_ANGEL_PATH_FR, element: <StoryBrokenAngel />, story_name: 'angel' },
  { en: STORY_BRICK_PATH, fr: STORY_BRICK_PATH_FR, element: <StoryBrick />, story_name: 'brick' },
  { en: STORY_STONE_PATH, fr: STORY_STONE_PATH_FR, element: <StoryStone />, story_name: 'stone' },
  { en: STORY_SKATE_PATH, fr: STORY_SKATE_PATH_FR, element: <StorySkate />, story_name: 'skate' },
  { en: STORY_LETTER_PATH, fr: STORY_LETTER_PATH_FR, element: <StoryLetter />, story_name: 'letter' },
  { en: STORY_DOOR_PATH, fr: STORY_DOOR_PATH_FR, element: <StoryDoor />, story_name: 'door' },
  { en: STORY_BRANCH_PATH, fr: STORY_BRANCH_PATH_FR, element: <StoryBranch />, story_name: 'branch' },
  { en: STORY_BRAIDS_PATH, fr: STORY_BRAIDS_PATH_FR, element: <StoryBraids />, story_name: 'braids' },
  { en: EXPLORE_BLANKET_PATH, fr: EXPLORE_BLANKET_PATH_FR, element: <MainSeries /> },
  { en: ABOUT_BLANKET_PATH, fr: ABOUT_BLANKET_PATH_FR, element: <AboutBlanket /> },
  { en: BEAR_WITNESS_PATH, fr: BEAR_WITNESS_PATH_FR, element: <BearWitness /> },
  { en: TEACHER_GUIDE_PATH, fr: TEACHER_GUIDE_PATH_FR, element: <TeacherGuide /> },
  { en: CONTACT_PATH, fr: CONTACT_PATH_FR, element: <ContactUs /> },
  { en: SITE_MAP_PATH, fr: SITE_MAP_PATH_FR, element: <Sitemap /> },
];

const App = () =>
  useRoutes(
    [{ path: '/', element: <MainSeries /> }].concat(
      ROUTES.filter(
        (route) =>
          !route.story_name ||
          STORY_MANAGER.find((story: StoryManagerItems) => route.story_name === story.story_name)?.isAvailable
      )
        .map((route) => ({ path: route.en, element: route.element }))
        .concat(
          ROUTES.filter(
            (route) =>
              !route.story_name ||
              STORY_MANAGER.find((story: StoryManagerItems) => route.story_name === story.story_name)?.isAvailable
          ).map((route) => ({ path: route.fr, element: route.element }))
        )
    )
  );

export default function AppWrapper() {
  // Set initial settings from Rails
  const dispatch = useDispatch();
  const node = document.getElementById('settings-data');
  const settingsData = node !== null ? JSON.parse(node.dataset.settings || '{}') : {};
  dispatch(setLanguageSettings(settingsData));

  return (
    <Router>
      <Layout>
        <Suspense fallback={null}>
          <App />
        </Suspense>
      </Layout>
    </Router>
  );
}
