import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../index';

export const SAFE_SPACE_WARNING_SHOWN_KEY = 'safeSpaceWarningShown';
export const GESTURE_TIPS_SHOWN_KEY = 'gestureTipsShown';

export interface SkipLink {
  title: string;
  id: string;
}

interface SettingsData {
  initialLanguage: string;
  baseUrls: { [key: string]: string };
}

interface SettingsState {
  initialLanguage: string;
  baseEnUrl: string;
  baseFrUrl: string;
  safeSpaceWarningShown: boolean;
  gestureTipsShown: boolean;
  isModalOpen: boolean;
  skipLinks: SkipLink[];
}

const initialState: SettingsState = {
  initialLanguage: 'en',
  baseEnUrl: '',
  baseFrUrl: '',
  safeSpaceWarningShown: localStorage.getItem(SAFE_SPACE_WARNING_SHOWN_KEY) === 'true',
  gestureTipsShown: localStorage.getItem(GESTURE_TIPS_SHOWN_KEY) === 'true',
  isModalOpen: false,
  skipLinks: [],
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setLanguageSettings: (state, action: PayloadAction<SettingsData>) => {
      state.initialLanguage = action.payload.initialLanguage;
      state.baseEnUrl = action.payload.baseUrls.en;
      state.baseFrUrl = action.payload.baseUrls.fr;
    },
    setSafeSpaceWarningShown: (state, action: PayloadAction<boolean>) => {
      state.safeSpaceWarningShown = action.payload;
      localStorage.setItem(SAFE_SPACE_WARNING_SHOWN_KEY, action.payload.toString());
    },
    setGestureTipsShown: (state, action: PayloadAction<boolean>) => {
      state.gestureTipsShown = action.payload;
      localStorage.setItem(GESTURE_TIPS_SHOWN_KEY, action.payload.toString());
    },
    setIsModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isModalOpen = action.payload;
      if (state.isModalOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'scroll';
      }
    },
    setSkipLinks: (state, action: PayloadAction<SkipLink[]>) => {
      state.skipLinks = action.payload;
    },
  },
});

export const { setLanguageSettings, setSafeSpaceWarningShown, setGestureTipsShown, setIsModalOpen, setSkipLinks } =
  settingsSlice.actions;

export const selectInitialLanguage = (state: RootState) => state.settings.initialLanguage;
export const selectBaseEnUrl = (state: RootState) => state.settings.baseEnUrl;
export const selectBaseFrUrl = (state: RootState) => state.settings.baseFrUrl;
export const selectSafeSpaceWarningShown = (state: RootState) => state.settings.safeSpaceWarningShown;
export const selectGestureTipsShown = (state: RootState) => state.settings.gestureTipsShown;
export const selectIsModalOpen = (state: RootState) => state.settings.isModalOpen;
export const selectSkipLinks = (state: RootState) => state.settings.skipLinks;

export default settingsSlice.reducer;
