import React, { useState } from 'react';

interface Props {
  isOpen?: boolean;
  onClick: () => void;
  className?: string;
}

export const MenuButton = ({ isOpen, onClick, className }: Props): React.ReactElement => {
  const [isFirstRun, setIsFirstRun] = useState(true);
  const classNameFormatted = className ? `${className} ` : '';
  const classNameState = isOpen ? 'am-menu__button--open' : 'am-menu__button--closed';
  const classes = `${classNameFormatted}am-menu__button ${classNameState}`;
  return (
    <button
      className={isFirstRun ? `${classNameFormatted}am-menu__button` : classes}
      onClick={() => {
        /* eslint-disable-next-line @typescript-eslint/no-unused-expressions */
        setIsFirstRun(false);
        onClick();
      }}
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label={`${isOpen ? 'Close' : 'Open'} Menu`}
      type="button"
    >
      <div className="am-menu__burger">
        <div className="am-menu__burger-top" />
        <div className="am-menu__burger-mid" />
        <div className="am-menu__burger-bot" />
      </div>
    </button>
  );
};

MenuButton.defaultProps = {
  isOpen: false,
  className: '',
};
