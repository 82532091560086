import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { store } from './store';
import './i18n';

import App from './components/App';

// loading component for suspense fallback
function Loader() {
  return <div className="layout-fallback" />;
}

const createApp = () => {
  const reactComponent = (
    <Suspense fallback={<Loader />}>
      <Provider store={store}>
        <App />
      </Provider>
    </Suspense>
  );

  return reactComponent;
};

const renderApp = () => {
  const container = document.getElementById('react-root');

  if (container) {
    ReactDOM.render(createApp(), document.getElementById('react-root'));
  }
};

document.addEventListener('DOMContentLoaded', renderApp);

if (module.hot) {
  module.hot.accept('./components/App', () => {
    renderApp();
  });
}
