import React from 'react';
import i18n from '../../../i18n';
import useTranslatedUrl from '../../utils/useTranslatedUrl';

interface Props {
  lang?: string;
  text?: string;
  className?: string;
}

function getSwitcherHref(lang?: string) {
  return useTranslatedUrl({
    path: window.location.pathname,
    fullPath: true,
    useCurrentLanguage: false,
    translatedLanguage: lang,
  });
}

function LanguageSwitcher({ lang, text, className }: Props) {
  const isEn = i18n.language === 'en';
  const targetLang = lang ?? (isEn ? 'fr' : 'en');
  const linkText = text ?? (isEn ? 'Français' : 'English');
  const switcherLink = getSwitcherHref(targetLang);
  return (
    <a href={switcherLink} className={className} lang={targetLang}>
      {linkText}
    </a>
  );
}

LanguageSwitcher.defaultProps = {
  lang: null,
  text: null,
  className: '',
};

export default LanguageSwitcher;
