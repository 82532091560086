import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectSkipLinks } from '../../../store/settings/settingsSlice';

export default function SkipLinks() {
  const skipLinks = useSelector(selectSkipLinks);
  if (!skipLinks.length) return null;

  const gotoAnchor = (link: string) => {
    const element = document.getElementById(link);
    if (element) {
      const position = element.getBoundingClientRect();
      const offset = parseFloat(getComputedStyle(element).scrollMarginTop);
      window.scrollTo(position.left, position.top + window.scrollY - offset);
      element.focus();
    }
  };

  return (
    <>
      {skipLinks.map((link) => (
        <a
          href={`#${link.id}`}
          onClick={(e) => {
            e.preventDefault();
            gotoAnchor(link.id);
          }}
          className="skipLink"
          key={link.title}
        >
          {link.title}
        </a>
      ))}
    </>
  );
}
