import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../index';

interface ShareState {
  showMenu: boolean;
  title: string;
  quote: string;
  author: string;
  mainUrl: string;
}

interface ShareData {
  title: string;
  mainUrl: string;
}

interface OpenMenuData {
  quote?: string;
  author?: string;
  mainUrl: string;
}

const initialState: ShareState = {
  showMenu: false,
  title: '',
  quote: '',
  author: '',
  mainUrl: '',
};

export const shareSlice = createSlice({
  name: 'share',
  initialState,
  reducers: {
    setShareData: (state, action: PayloadAction<ShareData>) => {
      state.title = action.payload.title;
      state.mainUrl = action.payload.mainUrl;
    },
    openMenu: (state, action: PayloadAction<OpenMenuData>) => {
      state.showMenu = true;
      if (action.payload.quote) state.quote = action.payload.quote;
      if (action.payload.author) state.author = action.payload.author;
      state.mainUrl = action.payload.mainUrl;
    },
    closeMenu: (state) => {
      state.showMenu = false;
      state.quote = '';
      state.author = '';
      state.mainUrl = '';
    },
  },
});

export const { setShareData, openMenu, closeMenu } = shareSlice.actions;

export const selectShowMenu = (state: RootState) => state.share.showMenu;
export const selectTitle = (state: RootState) => state.share.title;
export const selectQuote = (state: RootState) => state.share.quote;
export const selectAuthor = (state: RootState) => state.share.author;
export const selectMainUrl = (state: RootState) => state.share.mainUrl;

export default shareSlice.reducer;
