import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { usePopper } from 'react-popper';

import {
  selectScreenWidth,
  TABLET_MIN_WIDTH_IN_PX,
  NAV_MIN_WIDTH_IN_PX,
  MOBILE_HEADER_HEIGHT_IN_PX,
  TABLET_HEADER_HEIGHT_IN_PX,
  DESKTOP_HEADER_HEIGHT_IN_PX,
} from '../../../store/ui/uiSlice';
import { closeMenu, selectTitle, selectQuote, selectAuthor, selectMainUrl } from '../../../store/share/shareSlice';
import { openInNewTab, copyUrl, shareFB } from '../../utils/sharing';

import CopyIcon from '../../../../images/share_copy_icon.svg';
import FBIcon from '../../../../images/share_fb_icon.svg';
import TwitterIcon from '../../../../images/share_twitter_icon.svg';
import EmailIcon from '../../../../images/share_email_icon.svg';

interface Props {
  showMenu: boolean;
  className?: string;
  onClose?: () => void;
  popperReferenceElement?: HTMLElement | null;
  popperPlacement?: string;
}

export default function ShareMenu({ className, showMenu, onClose, popperReferenceElement, popperPlacement }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const mainUrl: string = useSelector(selectMainUrl);
  const title: string = useSelector(selectTitle);
  const quote: string = useSelector(selectQuote);
  const author: string = useSelector(selectAuthor);
  const screenWidth = useSelector(selectScreenWidth);

  const ref = useRef<HTMLDivElement>(null);

  const [showShareNotification, setShowShareNotification] = useState(false);
  const [shareNotificationMessage, setShareNotificationMessage] = useState('');
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);

  const calculatePopperPlacement = () => {
    return popperPlacement === 'top-end' ? 'top-end' : 'bottom-end';
  };

  const calculatePaddingOptions = () => {
    if (screenWidth < TABLET_MIN_WIDTH_IN_PX) {
      return {
        bottom: MOBILE_HEADER_HEIGHT_IN_PX,
      };
    }
    if (screenWidth < NAV_MIN_WIDTH_IN_PX) {
      return {
        top: TABLET_HEADER_HEIGHT_IN_PX,
      };
    }

    return { top: DESKTOP_HEADER_HEIGHT_IN_PX };
  };

  let rightSpace =
    window.innerWidth -
    ((popperReferenceElement?.getBoundingClientRect().x || 0) +
      (popperReferenceElement?.getBoundingClientRect().width || 0));

  if (rightSpace + 192 > window.innerWidth) {
    rightSpace -= rightSpace + 194 - window.innerWidth;
  }

  const notificationStyle = {
    right: `${rightSpace}px`,
    top: `${popperReferenceElement?.getBoundingClientRect().y}px`,
  };

  const POPPER_MODIFIERS_CONFIG = [
    {
      name: 'arrow',
      options: {
        element: arrowElement,
      },
    },
    {
      name: 'offset',
      options: {
        offset: [-12, 20],
      },
    },
    {
      name: 'flip',
      options: {
        padding: calculatePaddingOptions(),
      },
    },
  ];

  const {
    styles: popperStyles,
    attributes: popperAttributes,
    forceUpdate: forceUpdatePopper,
  } = usePopper(popperReferenceElement, popperElement, {
    placement: calculatePopperPlacement(),
    modifiers: POPPER_MODIFIERS_CONFIG,
  });

  popperReferenceElement?.setAttribute('aria-expanded', showMenu ? 'true' : 'false');

  useEffect(() => {
    // Hack to reforce repositioning of desktop share menu after it's shown
    if (forceUpdatePopper) {
      (async () => {
        /* eslint-disable no-promise-executor-return */
        await new Promise((resolve) => setTimeout(resolve, 50));
        forceUpdatePopper();
      })();
    }
  }, [showMenu]);

  const closeShareMenu = () => {
    if (onClose) onClose();
    dispatch(closeMenu());
    popperReferenceElement?.focus();
  };

  const displayNotification = (messageHandle: string) => {
    // Allow dynamic fetching of notification message
    /* eslint-disable @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    setShareNotificationMessage(t(`share_menu.${messageHandle}`));
    setShowShareNotification(true);

    const timeoutRef = setTimeout(() => {
      setShowShareNotification(false);
      setShareNotificationMessage('');
      clearTimeout(timeoutRef);
    }, 3500);
  };

  const handleHideDropdown = (event: KeyboardEvent) => {
    if (showMenu && event.key === 'Escape') {
      closeShareMenu();
    }
  };

  const handleClickOutside = (event: Event) => {
    if (
      showMenu &&
      ref.current &&
      !ref.current.contains(event.target as Node) &&
      (popperReferenceElement == null || !popperReferenceElement.contains(event.target as Node))
    ) {
      closeShareMenu();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleHideDropdown, true);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('keydown', handleHideDropdown, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const shareEmail = () => {
    const emailTitle = `${title} - ${t('share_email_title_suffix')}`;
    const emailBody = quote !== '' && author !== '' ? `“${quote}” - ${author}, ${mainUrl}` : mainUrl;

    openInNewTab(`mailto:?subject=${encodeURIComponent(emailTitle)}&body=${encodeURIComponent(emailBody)}`);
    closeShareMenu();
    displayNotification('share_email_notification');
  };

  const shareTwitter = () => {
    openInNewTab(
      `https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&via=${t(
        'social_media.twitter'
      )}&url=${encodeURIComponent(mainUrl)}`
    );
    closeShareMenu();
    displayNotification('share_twitter_notification');
  };

  return (
    <>
      <div
        className={`share-notification-container ${showShareNotification ? 'show-notification' : ''}`}
        style={notificationStyle}
      >
        <div className="share-notification" aria-live="assertive" aria-atomic="true">
          <span className="share-notification-message">{shareNotificationMessage}</span>
        </div>
      </div>
      <div
        className={`share-menu ${className} ${showMenu ? 'show-menu' : ''}`}
        ref={setPopperElement}
        style={popperStyles ? popperStyles.popper : undefined}
        {...(popperAttributes && popperAttributes.popper)}
      >
        <div className="menu-container" ref={ref}>
          <h3 className="menu-title"> {t('share_menu.title')} </h3>
          <ul className="menu-items" role="menu">
            <li role="none">
              <button
                role="menuitem"
                className="menu-item"
                type="button"
                onClick={() => {
                  copyUrl(mainUrl);
                  closeShareMenu();
                  displayNotification('share_link_notification');
                }}
              >
                <span className="menu-item-icon">
                  <img src={CopyIcon} className="share-icon" alt="Copy Link" />
                </span>
                {t('share_menu.share_link')}
              </button>
            </li>
            <li role="none">
              <button
                role="menuitem"
                className="menu-item"
                type="button"
                onClick={() => {
                  shareFB(mainUrl);
                  closeShareMenu();
                  displayNotification('share_facebook_notification');
                }}
              >
                <span className="menu-item-icon">
                  <img src={FBIcon} className="share-icon" alt="Share on Facebook" />
                </span>
                {t('share_menu.share_facebook')}
              </button>
            </li>
            <li role="none">
              <button role="menuitem" className="menu-item" type="button" onClick={() => shareTwitter()}>
                <span className="menu-item-icon">
                  <img src={TwitterIcon} className="share-icon" alt="Share on Twitter" />
                </span>
                {t('share_menu.share_twitter')}
              </button>
            </li>
            <li role="none">
              <button role="menuitem" className="menu-item" type="button" onClick={() => shareEmail()}>
                <span className="menu-item-icon">
                  <img src={EmailIcon} className="share-icon" alt="Share with Email" />
                </span>
                {t('share_menu.share_email')}
              </button>
            </li>
          </ul>
        </div>
        <div ref={setArrowElement} className="menu-arrow" style={popperStyles ? popperStyles.arrow : undefined} />
      </div>
    </>
  );
}

ShareMenu.defaultProps = {
  className: '',
  onClose: () => {},
  popperReferenceElement: null,
  popperPlacement: '',
};
