import * as React from 'react';
import { NavLink as ReactRouterNavLink, Link as ReactRouterLink } from 'react-router-dom';
import useTranslatedUrl from '../../utils/useTranslatedUrl';

interface Props {
  isNavLink?: boolean;
  to: string;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  children: React.ReactNode;
}

export default function Link({ isNavLink, to, children, ...others }: Props) {
  return isNavLink ? (
    <ReactRouterNavLink to={useTranslatedUrl({ path: to })} {...others}>
      {children}
    </ReactRouterNavLink>
  ) : (
    <ReactRouterLink to={useTranslatedUrl({ path: to })} {...others}>
      {children}
    </ReactRouterLink>
  );
}

Link.defaultProps = {
  isNavLink: false,
  className: '',
  onClick: null,
};
