import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player/lazy';
import Modal from 'react-modal';
import { MenuButton } from './MenuButton';
import MenuSplitter from './MenuSplitter';
import { SafeSpaceButton } from './SafeSpaceButton';
import Link from '../Link';

import { isDesktop } from '../../utils/isDesktop';
import LanguageSwitcher from '../LanguageSwitcher';

import { selectIsMobile, selectIsReducedMotion } from '../../../store/ui/uiSlice';
import { selectIsModalOpen, setIsModalOpen } from '../../../store/settings/settingsSlice';

import CloseIcon from '../../../../images/close_icon.svg';

import {
  ABOUT_ARTIST_PATH,
  STORIES_PATH,
  EXPLORE_BLANKET_PATH,
  ABOUT_BLANKET_PATH,
  BEAR_WITNESS_PATH,
  TEACHER_GUIDE_PATH,
  TEACHER_GUIDE_PATH_FR,
  CONTACT_PATH,
  WELCOME_PATH,
} from '../../utils/urlPaths';

interface Props {
  title?: string;
}

export default function Navbar({ title }: Props) {
  Modal.setAppElement('#react-root');

  const SAFE_SPACE_LEAVE_LINK = 'https://humanrights.ca/';

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [navbarType, setNavbarType] = useState('menu');
  const [menuOpen, setMenuOpen] = useState(false);
  const [safeSpaceIsOpen, setSafeSpaceIsOpen] = useState(false);
  const [safeSpaceIsLoaded, setSafeSpaceIsLoaded] = useState(false);
  const [safeSpaceWarningIsOpen, setSafeSpaceWarningIsOpen] = useState(false);
  const [safeSpaceWarningIsFirstTime, setSafeSpaceWarningIsFirstTime] = useState(true);
  const isMobile = useSelector(selectIsMobile);
  const isReducedMotion = useSelector(selectIsReducedMotion);
  const modalOpen = useSelector(selectIsModalOpen);

  const menuNavbarRef = useRef<HTMLDivElement>(null);
  const safeSpaceNavbarRef = useRef<HTMLDivElement>(null);
  const closeSafeSpaceSupportRef = useRef<HTMLButtonElement>(null);
  const firstSafeSpaceButtonRef = useRef<HTMLButtonElement>(null);

  const isWide = isDesktop();
  const isNarrow = !isWide;

  const navbarClass = () => {
    if (safeSpaceIsOpen) {
      return 'safe-space--open';
    }
    if (menuOpen) {
      return 'menu--open';
    }
    if (navbarType === 'safe-space') {
      return 'safe-space--closed';
    }
    return 'menu--closed';
  };

  const navbarTitle = () => {
    if (safeSpaceIsOpen) {
      return t('safe_space_open');
    }
    if (menuOpen) {
      return t('menu_open');
    }
    return title;
  };

  const closeMenu = () => {
    setMenuOpen(false);
    dispatch(setIsModalOpen(false));

    // Manually simulate bootstrap navbar btn click
    if (menuNavbarRef.current !== null) {
      menuNavbarRef.current.classList.remove('show');
    }
  };
  const closeSafeSpace = () => {
    setSafeSpaceWarningIsOpen(false);
    setSafeSpaceIsOpen(false);
    dispatch(setIsModalOpen(false));

    // Manually simulate bootstrap navbar btn click
    if (safeSpaceNavbarRef.current !== null) {
      safeSpaceNavbarRef.current.classList.remove('show');
    }
  };
  const closeSafeSpaceWarning = () => {
    setSafeSpaceWarningIsFirstTime(false);
    setSafeSpaceWarningIsOpen(false);
  };

  const afterSupportOpenModal = () => {
    closeSafeSpaceSupportRef?.current?.focus();
  };

  const afterSupportCloseModal = () => {
    firstSafeSpaceButtonRef?.current?.focus();
  };

  const onToggleMenu = () => {
    setNavbarType('menu');

    closeSafeSpace();
    setTimeout(() => {
      setMenuOpen((prev) => !prev);
      dispatch(setIsModalOpen(!modalOpen));
    }, 150);
  };
  const onToggleSafeSpace = () => {
    setNavbarType('safe-space');

    closeMenu();
    setTimeout(() => {
      if (safeSpaceIsOpen) {
        closeSafeSpace();
      } else {
        setSafeSpaceWarningIsOpen(true);
        setSafeSpaceIsOpen(true);
        dispatch(setIsModalOpen(true));
      }
    }, 150);
  };

  const gotoSupport = () => {
    setSafeSpaceWarningIsFirstTime(true);
    setSafeSpaceWarningIsOpen(true);
  };
  const gotoReturn = () => {
    closeSafeSpace();
  };
  const gotoLeave = () => {
    window.location.assign(SAFE_SPACE_LEAVE_LINK);
  };

  const isTeacherGuidePage = () => {
    return (
      window.location.href.indexOf(TEACHER_GUIDE_PATH) > -1 || window.location.href.indexOf(TEACHER_GUIDE_PATH_FR) > -1
    );
  };

  const navOptions = [
    { url: WELCOME_PATH, option: t('nav_options.welcome') },
    { url: STORIES_PATH, option: t('nav_options.stories') },
    { url: EXPLORE_BLANKET_PATH, option: t('nav_options.blanket') },
    { url: BEAR_WITNESS_PATH, option: t('nav_options.bear_witness') },
    { url: TEACHER_GUIDE_PATH, option: t('nav_options.teacher_guide') },
    { url: t('resource_url'), option: t('nav_options.resources') },
    { url: ABOUT_BLANKET_PATH, option: t('nav_options.about_blanket') },
    { url: ABOUT_ARTIST_PATH, option: t('nav_options.about_artist') },
    { url: CONTACT_PATH, option: t('nav_options.contact_us') },
  ];
  const navOptionsDesktop = [
    { url: STORIES_PATH, option: t('nav_options.stories_desktop') },
    { url: EXPLORE_BLANKET_PATH, option: t('nav_options.blanket') },
    { url: BEAR_WITNESS_PATH, option: t('nav_options.bear_witness') },
    { url: TEACHER_GUIDE_PATH, option: t('nav_options.teacher_guide') },
    { url: t('resource_url'), option: t('nav_options.resources') },
  ];

  const roleOptions = isNarrow ? { role: 'dialog' } : {};
  return (
    <>
      <nav className={`navbar ${navbarClass()}`} aria-label="Main">
        <Link to="/" className="header-title header-title-wide">
          {t('nav_title.default')}
        </Link>
        <MenuButton className="btn-main-nav" isOpen={menuOpen} onClick={onToggleMenu} />
        <div className={`header-title header-title-narrow ${menuOpen ? 'menu-title-open' : ''}`}>{navbarTitle()}</div>
        <div
          className="collapse navbar-collapse"
          id="navbarSupportedContent"
          ref={menuNavbarRef}
          {...roleOptions}
          aria-modal={isNarrow ? 'true' : 'false'}
          aria-label={isNarrow ? t('mobile_menu_aria') : t('desktop_menu_aria')}
        >
          <ul className="navbar-nav" aria-labelledby="navbarSupportedContent">
            {/* eslint-disable no-nested-ternary */}
            {isWide ? (
              isTeacherGuidePage() ? (
                <>
                  <li>
                    <LanguageSwitcher className="header-item" text="English" lang="en" />
                  </li>

                  <li>
                    <MenuSplitter />
                    <LanguageSwitcher className="header-item" text="Français" lang="fr" />
                  </li>
                </>
              ) : (
                <>
                  {navOptionsDesktop.map((item) => (
                    <li key={item.url}>
                      {(item.url as string).startsWith('/') ? (
                        <Link isNavLink to={item.url} className="header-item" onClick={closeMenu}>
                          {item.option}
                        </Link>
                      ) : (
                        <a href={item.url} target="_blank" rel="noreferrer" className="header-item">
                          {item.option}
                        </a>
                      )}
                    </li>
                  ))}
                  <li>
                    <MenuSplitter />
                    <LanguageSwitcher className="header-item" />
                  </li>
                </>
              )
            ) : (
              <>
                <h2>{t('menu_open')}</h2>
                {navOptions.map((item) => (
                  <li key={item.url}>
                    {(item.url as string).startsWith('/') ? (
                      <Link isNavLink to={item.url} className="header-item" onClick={closeMenu}>
                        {item.option}
                      </Link>
                    ) : (
                      <a href={item.url} target="_blank" rel="noreferrer" className="header-item">
                        {item.option}
                      </a>
                    )}
                  </li>
                ))}
                <li>
                  <LanguageSwitcher className="header-item" />
                </li>
              </>
            )}
          </ul>
        </div>

        <div className="safe-space">
          <SafeSpaceButton isOpen={safeSpaceIsOpen} onClick={onToggleSafeSpace} />
        </div>

        {isNarrow && (
          <div
            className={`collapse navbar-collapse ${safeSpaceIsOpen ? 'show' : ''}`}
            id="navbarSafeSpaceContent"
            ref={safeSpaceNavbarRef}
          >
            <div className="safe-space-outer-container">
              <div className="safe-space-inner-container">
                <div className="title-container"> {t('safe_space.title')} </div>
                <div className="action-container">
                  {/* eslint-disable react/no-danger */}
                  <button
                    type="button"
                    className="action-btn-secondary"
                    onClick={() => gotoSupport()}
                    dangerouslySetInnerHTML={{ __html: t('safe_space.button_support') }}
                  />
                  <button
                    type="button"
                    className="action-btn-secondary"
                    onClick={() => gotoReturn()}
                    dangerouslySetInnerHTML={{ __html: t('safe_space.button_return') }}
                  />
                  <button
                    type="button"
                    className="action-btn-primary"
                    onClick={() => gotoLeave()}
                    dangerouslySetInnerHTML={{ __html: t('safe_space.button_leave') }}
                  />
                </div>

                <ReactPlayer
                  height="100%"
                  width="100%"
                  url={`https://www.youtube.com/watch?v=${
                    isMobile ? t('safe_space.video_portrait_id') : t('safe_space.video_landscape_id')
                  }`}
                  config={{
                    youtube: {
                      playerVars: {
                        autoplay: isReducedMotion ? 0 : 1,
                        showinfo: 0,
                        cc_load_policy: 0,
                        iv_load_policy: 3,
                        modestbranding: 1,
                        playlist: t('safe_space.video_landscape_id'),
                      },
                    },
                  }}
                  playing={!isReducedMotion}
                  muted
                  loop
                  className="youtube-player"
                  style={{ opacity: safeSpaceIsLoaded ? 1 : 0 }}
                  onStart={() => setSafeSpaceIsLoaded(true)}
                />
                <div className="dark-overlay" />
              </div>
            </div>
          </div>
        )}
      </nav>
      {window.location.href.indexOf('story/') > -1 ? (
        <div className="breadcrumb-bar">
          <nav className="crumbs" aria-label={t('breadcrumb_aria')}>
            <h2 id="breadcrumbNavigation" className="offscreen-navigation">
              Breadcrumb Navigation
            </h2>
            <ol aria-labelledby="breadcrumbNavigation">
              <li>
                <Link to="/stories" className="crumb-title">
                  {t('breadcrumbs')}
                </Link>
              </li>
              <li>
                <span aria-hidden="true"> / </span>
                <span aria-current="page">{title}</span>
              </li>
            </ol>
          </nav>
        </div>
      ) : undefined}

      {isWide && (
        <Modal
          isOpen={safeSpaceIsOpen}
          onRequestClose={closeSafeSpace}
          className="safe-space-modal"
          overlayClassName="safe-space-modal-overlay"
          closeTimeoutMS={500}
          contentLabel={t('safe_space.title')}
        >
          <button className="safe-space-modal-close" type="button" onClick={closeSafeSpace}>
            <img src={CloseIcon} className="close-icon" alt={t('close_modal')} loading="lazy" />
          </button>

          <h1 className="title-container" id="safeSpaceModal">
            {' '}
            {t('safe_space.title')}{' '}
          </h1>
          <div className="action-container">
            <button
              type="button"
              ref={firstSafeSpaceButtonRef}
              className="action-btn-secondary"
              onClick={() => gotoSupport()}
              dangerouslySetInnerHTML={{ __html: t('safe_space.button_support') }}
            />
            <button
              type="button"
              className="action-btn-secondary"
              onClick={() => gotoReturn()}
              dangerouslySetInnerHTML={{ __html: t('safe_space.button_return') }}
            />
            <button
              type="button"
              className="action-btn-primary"
              onClick={() => gotoLeave()}
              dangerouslySetInnerHTML={{ __html: t('safe_space.button_leave') }}
            />
          </div>

          <span aria-hidden="true">
            <ReactPlayer
              height="100%"
              width="100%"
              url={`https://www.youtube.com/watch?v=${t('safe_space.video_landscape_id')}`}
              config={{
                youtube: {
                  playerVars: {
                    autoplay: isReducedMotion ? 0 : 1,
                    showinfo: 0,
                    cc_load_policy: 0,
                    iv_load_policy: 3,
                    modestbranding: 1,
                    playlist: t('safe_space.video_landscape_id'),
                  },
                },
              }}
              playing={!isReducedMotion}
              muted
              loop
              className="youtube-player"
              style={{ opacity: safeSpaceIsLoaded ? 1 : 0 }}
              onStart={() => setSafeSpaceIsLoaded(true)}
            />
          </span>
          <div className="dark-overlay" />
        </Modal>
      )}

      <Modal
        isOpen={safeSpaceWarningIsOpen && safeSpaceWarningIsFirstTime}
        onRequestClose={closeSafeSpaceWarning}
        onAfterOpen={afterSupportOpenModal}
        onAfterClose={afterSupportCloseModal}
        className="safe-space-warning-modal"
        overlayClassName="safe-space-warning-modal-overlay"
        closeTimeoutMS={500}
        contentLabel={t('safe_space.support_information')}
      >
        <div className="warning-container">
          <button
            className="safe-space-warning-modal-close"
            type="button"
            onClick={closeSafeSpaceWarning}
            ref={closeSafeSpaceSupportRef}
          >
            <img src={CloseIcon} className="close-icon" alt={t('close_modal')} loading="lazy" />
          </button>

          {/* eslint-disable react/no-danger, jsx-a11y/control-has-associated-label */}
          <div className="text-container" dangerouslySetInnerHTML={{ __html: t('safe_space.support_text') }} />
        </div>
      </Modal>
    </>
  );
}

Navbar.defaultProps = {
  title: 'The Witness Blanket',
};
