import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../index';

interface UiState {
  screenWidth: number;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isReducedMotion: boolean;
}

interface UpdateMediaQueryData {
  isTablet: boolean;
  isDesktop: boolean;
  isReducedMotion: boolean;
}

const initialState: UiState = {
  screenWidth: typeof window === 'object' ? window.innerWidth : 0,
  isMobile: true,
  isTablet: false,
  isDesktop: false,
  isReducedMotion: false,
};

export const TABLET_MIN_WIDTH_IN_PX = 576;
export const TABLET_MIN_WIDTH_IN_EM = 36;
const DESKTOP_MIN_WIDTH_IN_EM = 62;
export const LARGE_DESKTOP_MIN_WIDTH_IN_PX = 1202;
export const LARGE_DESKTOP_MIN_WIDTH_IN_EM = 75;
export const NAV_MIN_WIDTH_IN_PX = 1350;
export const NAV_MIN_WIDTH_IN_EM = 84;
const REDUCED_QUERY = '(prefers-reduced-motion: reduce)';

export const MOBILE_HEADER_HEIGHT_IN_PX = 48;
export const TABLET_HEADER_HEIGHT_IN_PX = 58;
export const DESKTOP_HEADER_HEIGHT_IN_PX = 131;

export const tabletMediaQuery = window.matchMedia(`(min-width: ${TABLET_MIN_WIDTH_IN_EM}em)`);
export const tabletMediaQueryRem = window.matchMedia(`(min-width: ${TABLET_MIN_WIDTH_IN_EM}em)`);
export const desktopMediaQuery = window.matchMedia(`(min-width: ${DESKTOP_MIN_WIDTH_IN_EM}em)`);
export const desktopMediaQueryRem = window.matchMedia(`(min-width: ${DESKTOP_MIN_WIDTH_IN_EM}em)`);
export const reducedMotionMediaQuery = window.matchMedia(REDUCED_QUERY);

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    resizeScreen: (state, action: PayloadAction<number>) => {
      state.screenWidth = action.payload;
    },
    updateMediaQuery: (state, action: PayloadAction<UpdateMediaQueryData>) => {
      if (action.payload.isTablet) {
        if (action.payload.isDesktop) {
          state.isMobile = false;
          state.isTablet = false;
          state.isDesktop = true;
        } else {
          state.isMobile = false;
          state.isTablet = true;
          state.isDesktop = false;
        }
      } else {
        state.isMobile = true;
        state.isTablet = false;
        state.isDesktop = false;
      }
      if (action.payload.isReducedMotion) state.isReducedMotion = true;
      else state.isReducedMotion = false;
    },
  },
});

export const { resizeScreen, updateMediaQuery } = uiSlice.actions;

export const selectScreenWidth = (state: RootState) => state.ui.screenWidth;
export const selectIsMobile = (state: RootState) => state.ui.isMobile;
export const selectIsTablet = (state: RootState) => state.ui.isTablet;
export const selectIsDesktop = (state: RootState) => state.ui.isDesktop;
export const selectIsReducedMotion = (state: RootState) => state.ui.isReducedMotion;

export default uiSlice.reducer;
